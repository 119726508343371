.navbar {
	overflow: hidden;
	// background-color: #333;
}

.navbar a {
	float: left;
	font-size: 16px;
	text-align: center;
	padding: 8px 6px;
	text-decoration: none;
}

.dropdown {
	float: left;
	overflow: hidden;
	margin-right: 20px;
}

.dropdown .dropbtn {
	font-size: 16px;
	border: none;
	outline: none;
	// color: white;
	// padding: 8px 6px;
	background-color: inherit;
	font-family: inherit;
	margin: 0;
}

.navbar a:hover,
.dropdown:hover .dropbtn {
	// background-color: red;
}

.dropdown-content {
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
}

.dropdown-content a {
	float: none;
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
	text-align: left;
}

.dropdown-content a:hover {
	background-color: #ddd;
}

.dropdown:hover .dropdown-content {
	display: block;
}
