.rbc-toolbar .rbc-toolbar-label {
	font-weight: bold;
	font-size: 22px;
	padding: 20px 0px !important;
}

.rbc-toolbar {
	position: relative;
}
.rbc-toolbar .rbc-btn-group {
	position: absolute;
	top: -26px;
	right: 0px;
}

.rbc-header {
	padding: 6px 0px !important;
}
